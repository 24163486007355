import React, { useEffect } from 'react';
import { Box } from '@dripcapital/dripui';
import { init } from 'onfido-sdk-ui';

type Props = {
  token: string;
  uploadCompleted: () => void;
};

const OnFidoContainer: React.FC<Props> = ({
  token,
  uploadCompleted,
}: Props) => {
  useEffect(() => {
    init({
      token,
      containerId: 'onfido-mount',
      useMemoryHistory: true,
      onComplete: function () {
        uploadCompleted();
      },
      steps: ['welcome', 'document', 'face', 'complete'],
    });
  });

  return (
    <Box dataTestId="onfido-section">
      <Box id="onfido-mount" />
    </Box>
  );
};

export default OnFidoContainer;

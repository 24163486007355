import {
  BusinessDetailsType,
  RFBusinessEligibilityType,
  SCFBusinessDetailsType,
  SCFBusinessEligibilityType,
  BusinessIdentifierType,
  ShareholderType,
  SCFAssistedRegBusinessType,
  AuthorizationContentType,
  USCompanyInfoType,
  ScfOwnerType,
} from 'types';

/**
 * initial values
 */

export const FINANCIAL_INFO_INITIAL_VALUES: AuthorizationContentType = {
  companyName: '',
  rfc: '',
  gender: '',
  name: {
    firstname: '',
    middlename: '',
    lastname: '',
  },
  email: '',
  address: {
    city: '',
    country: 'mx',
    municipality: '',
    state: '',
    street: '',
    zipcode: '',
  },
};

export const US_PERSONAL_INFO_INITIAL_VALUES = {
  firstName: '',
  middleName: '',
  lastName: '',
  phone: {
    countryCode: '',
    phoneNumber: '',
  },
  citizenship: '',
  ssn: '',
  country: '',
  street: '',
  unit: '',
  city: '',
  state: '',
  zipCode: '',
};

export const US_COMPANY_INFO_INITIAL_VALUES: USCompanyInfoType = {
  companyName: '',
  industry: '',
  incorporationYear: '',
  country: '',
  street: '',
  building: '',
  state: '',
  city: '',
  zipcode: '',
  dba: '',
  companyWebsite: '',
  goodsValue: '',
  otherField: '',
  ein: '',
  updateType: '',
  ownershipPercentage: 0,
};

export const US_OWNER_INFO_INTIAL_VALUES: ScfOwnerType = {
  firstname: '',
  lastname: '',
  email: '',
  phone: {
    countryCode: '',
    phoneNumber: '',
  },
  ownershipPercentage: '',
};

export const BUSINESS_DETAILS_INITIAL_VALUES: BusinessDetailsType = {
  companyName: '',
  industry: '',
  companyAddress: '',
  country: '',
  region_state: '',
  pincode: '',
};

export const BUSINESS_IDENTIFIER_INITIAL_VALUES: BusinessIdentifierType = {
  businessIdentifier: '',
};

export const RF_BUSINESS_ELIGIBILITY_INITIAL_VALUES: RFBusinessEligibilityType =
  {
    goodsType: '',
    productCategory: '',
  };

export const SCF_BUSINESS_DETAILS_INITIAL_VALUES: SCFBusinessDetailsType = {
  country: 'United States Of America',
  region_state: '',
  goodsType: '',
  productCategory: '',
};

export const SCF_BUSINESS_ELIGIBILITY_INITIAL_VALUES: SCFBusinessEligibilityType =
  {
    ...RF_BUSINESS_ELIGIBILITY_INITIAL_VALUES,
    lastTwelveMonthsRevenue: '',
    lastTwelveMonthsShipment: 0,
  };

export const SHAREHOLDER_INFO_INITIAL_VALUES: ShareholderType = {
  dob: '',
  gender: '',
  identificationCardNumber: '',
  shareholderName: '',
  isLegalRepresentative: false,
};

export const SCF_ASSISTED_REGISTER_COMPANY_IV: SCFAssistedRegBusinessType = {
  ...BUSINESS_IDENTIFIER_INITIAL_VALUES,
  ...BUSINESS_DETAILS_INITIAL_VALUES,
  ...SCF_BUSINESS_DETAILS_INITIAL_VALUES,
};

export const PRODUCTS_CATEGORIES = [
  { value: '302020', label: 'Processed Food' },
  { value: '302020B', label: 'Frozen Sea Food' },
  { value: '302020C', label: 'Frozen Meat' },
];

/**
 * data conversions
 */
export const INDUSTRY_OPTIONS = [
  { value: 'textile', label: 'Textile' },
  { value: 'food', label: 'Food' },
  { value: 'metals', label: 'Metals' },
];
